<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div>
    <div class="text-container">
        <div class="title">会社概要</div>
        <div class="main">
          <div class="item-container">
            <div class="item-title">社名</div>
            <div class="text-container"><span>三恒一樹 株式会社</span></div>
          </div>
          <div class="item-container">
            <div class="item-title">本社</div>
            <div class="text-container">
              <span>〒262-0032 千葉市花見川区幕張町5-417-9-1205</span>
              <span>TEL：043-375-3107</span>
              <span>FAX：043-375-3107</span>
            </div>
          </div>
          <div class="item-container">
            <div class="item-title">船橋店舗</div>
            <div class="text-container">
              <span>〒274-0065千葉県船橋市高根台6-2-25（MOTO VISION LAND）</span>
              <span>TEL：043-375-3107</span>
              <span>FAX：043-375-3107</span>
            </div>
          </div>
          <div class="item-container">
            <div class="item-title">設立</div>
            <div class="text-container"><span>平成31年 3 月 5 日</span></div>
          </div>
          <div class="item-container">
            <div class="item-title">資本金</div>
            <div class="text-container"><span>630万円</span></div>
          </div>
          <div class="item-container">
            <div class="item-title">代表者</div>
            <div class="text-container">
              <span>代表取締役社長 WANG HAO</span>
            </div>
          </div>
          <div class="item-container">
            <div class="item-title">事業内容</div>
            <div class="text-container">
              <span>オートバイ用品・部品卸販売</span>
              <span>DIN MARKET・オートバイ部品代理店</span>
            </div>
          </div>
          <div class="item-container">
            <div class="item-title">従業員数</div>
            <div class="text-container"><span>5人</span></div>
          </div>
        </div>
        <div class="between-logo-container">
        <img src="@/assets/between-logo.png" />
      </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'MobileAssociation'
}
</script>

<style lang="scss" scoped>
.text-container {
  margin: 10px auto;
  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .main {
    border: 1px solid #fff;
    border-top: none;
    .item-container {
      display: flex;
      font-size: 12px;
      align-items: center;
      border-top: 1px solid #fff;
      .item-title {
        padding: 10px 0;
        width: 80px;
        display: flex;
        justify-content: center;
      }
      .text-container {
        border-left: 1px solid #fff;
        padding: 10px 0 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        flex: 1;
        span {
          text-align: left;
        }
      }
    }
  }
}
.between-logo-container {
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
    img {
      display: block;
      width: 100%;
      box-shadow: 1px 0 3px 4px rgb(0, 0, 0),
        3px 2px 3px 4px rgb(255, 255, 255, 0.7);
    }
  }
</style>
